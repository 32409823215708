<template>
  <div>
    <div v-if="isAgencyFilter" class="form-row">
      <CCol md="3">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-5 col-md-4 text-right">
              Cửa hàng
            </label>
            <v-select
              v-model="agencyId"
              :options="agencies"
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng"
              class="col-7 col-md-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3" v-if="sharedBillOnly">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-5 col-md-4 text-right">
              Được chia bill
            </label>
            <v-select
              v-model="sharedAgencyId"
              :options="sharedAgencies"
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng được chia bill"
              class="col-7 col-md-8"
            />
          </div>
        </div>
      </CCol>
    </div>
    <div class="form-row">
      <CCol md="3">
        <CInput
          label="Mã đơn"
          placeholder="#Mã đơn"
          v-model="orderNumber"
          :horizontal="{ label: 'col-5 text-right', input: 'col-7' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="2">
        <div role="group" class="form-group form-row">
          <label class="col-5 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-7">
            <datetime
              type="date"
              v-model="createdFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="2">
        <div role="group" class="form-group form-row">
          <label class="col-5 text-right col-form-label">
            Đến ngày
          </label>
          <div class="col-7">
            <datetime
              type="date"
              v-model="createdTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <CSelect
          label="Trạng thái"
          placeholder="Trạng thái"
          :value.sync="orderStatus"
          :options="[
            { value: null, label: 'Tất cả' },
            ...Object.keys($const.ORDER_STATUS_TEXT).map((_) => {
              return { value: _, label: $const.ORDER_STATUS_TEXT[_] };
            }),
          ]"
          :horizontal="{ label: 'col-5 text-right', input: 'col-7' }"
        />
      </CCol>
      <CCol md="2">
        <div role="group" class="form-group form-row">
          <label class="col-8 col-form-label text-right">
            Chỉ bill chia
          </label>
          <div class="col-4 position-relative">
            <CSwitch
              style="top: 5px;"
              class="position-absolute"
              color="info"
              size="sm"
              variant="opposite"
              shape="pill"
              :checked.sync="sharedBillOnly"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <CInput
          label="SĐT KH"
          placeholder="SĐT KH"
          v-model="custMobile"
          :horizontal="{ label: 'col-5 text-right', input: 'col-7' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="3">
        <CInput
          label="Họ Tên KH"
          placeholder="Họ Tên KH"
          v-model="custName"
          :horizontal="{ label: 'col-5 text-right', input: 'col-7' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="3">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-5 col-md-4 text-right">
              Nguồn / Kênh
            </label>
            <v-select
              v-model="source"
              :options="
                Object.keys(sources).map((s) => {
                  return {
                    Id: s,
                    Name: sources[s],
                  };
                })
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Nguồn ghé thăm"
              class="col-7 col-md-8"
            />
          </div>
        </div>
      </CCol>

      <CCol md="3" class="text-center">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    datetime: Datetime,
  },
  props: ["sources"],
  data() {
    return {
      orderNumber: null,
      createdFrom: null,
      createdTo: null,
      orderStatus: null,
      agencies: [],
      sharedAgencies: [],
      agencyId: null,
      sharedAgencyId: null,
      sharedBillOnly: false,
      custMobile: null,
      custName: null,
      source: null,
    };
  },
  computed: {
    isAgencyFilter() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser.role == this.$const.ROLES.SuperAdmin ||
            (authUser.role == this.$const.ROLES.Owner &&
              authUser.agencyIds &&
              authUser.agencyIds.includes(","))
        : false;
    },
  },
  async mounted() {
    if (this.isAgencyFilter) {
      this.agencies = await this.loadAgencies(false);
      this.sharedAgencies = await this.loadAgencies(true);
    }
  },
  methods: {
    async loadAgencies(isAll) {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          isAll,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    filter() {
      let filters = [];
      this.orderNumber && filters.push(`OrderNumber eq '${this.orderNumber}'`);
      if (this.createdFrom) {
        let createdFrom = this.$moment(this.createdFrom).toISOString();
        filters.push(`CreatedAt ge ${createdFrom}`);
      }
      if (this.createdTo) {
        let createdTo = this.$moment(this.createdTo)
          .add(1, "days")
          .toISOString();
        filters.push(`CreatedAt lt ${createdTo}`);
      }
      this.orderStatus && filters.push(`Status eq ${this.orderStatus}`);
      this.agencyId && filters.push(`Visit/Agency/Id eq ${this.agencyId}`);
      if (this.sharedBillOnly) {
        if (this.sharedAgencyId) {
          filters.push(
            `OrderShareds/any(os:os/SharedAgencyId eq ${this.sharedAgencyId})`
          );
        } else {
          filters.push(`OrderShareds/any()`);
        }
      }

      this.custMobile &&
        filters.push(`contains(Customer/Mobile, '${this.custMobile}')`);
      this.custName &&
        filters.push(`contains(Customer/Name, '${this.custName}')`);

      this.source && filters.push(`Visit/Source eq '${this.source}'`);

      this.$emit("done", filters);
    },

    clear() {
      this.orderNumber = null;
      this.createdFrom = null;
      this.createdTo = null;
      this.orderStatus = null;
      this.agencyId = null;
      this.sharedAgencyId = null;
      this.custMobile = null;
      this.custName = null;
    },
  },
};
</script>
