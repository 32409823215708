<template>
  <CModal
    :title="`Chi tiết đơn hàng #${order ? order.OrderNumber : ''}`"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="xl"
  >
    <div v-if="loading" class="spinner-border m-5" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <template v-if="order" v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        :style="
          `max-height: calc(100vh - ${orderStatus ? '15rem' : '12.1rem'});`
        "
      >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4 mb-2" v-if="billAddress">
                <h6 class="mb-3">ĐỊA CHỈ HÓA ĐƠN:</h6>
                <div>
                  <strong
                    >{{ billAddress.Title }} {{ billAddress.FullName }}</strong
                  >
                </div>
                <div class="form-row">
                  <div class="col-4">Địa chỉ:</div>
                  <div class="col-8 font-italic">
                    {{ billAddress.Address }}
                  </div>
                </div>
                <div class="font-italic">
                  {{
                    [billAddress.Ward, billAddress.District]
                      .filter((_) => _)
                      .join(", ")
                  }}
                </div>
                <div class="font-italic">{{ billAddress.Province }}</div>
                <div class="form-row" v-if="billAddress.Mobile">
                  <div class="col-4">Điện thoại:</div>
                  <div class="col-8">{{ billAddress.Mobile }}</div>
                </div>
              </div>

              <div class="col-sm-4 mb-2">
                <template v-if="deliveryAddress">
                  <h6 class="mb-3">ĐỊA CHỈ GIAO HÀNG:</h6>
                  <div>
                    <strong
                      >{{ deliveryAddress.Title }}
                      {{ deliveryAddress.FullName }}</strong
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-4">Địa chỉ:</div>
                    <div class="col-8 font-italic">
                      {{ deliveryAddress.Address }}
                    </div>
                  </div>
                  <div class="font-italic">
                    {{
                      [deliveryAddress.Ward, deliveryAddress.District]
                        .filter((_) => _)
                        .join(", ")
                    }}
                  </div>
                  <div class="font-italic">{{ deliveryAddress.Province }}</div>
                  <div class="form-row" v-if="deliveryAddress.Mobile">
                    <div class="col-4">Điện thoại:</div>
                    <div class="col-8">
                      {{ deliveryAddress.Mobile }}
                    </div>
                  </div>
                </template>
                <div class="form-row">
                  <div class="col-4">Dự kiến GH:</div>
                  <div class="col-8">
                    {{
                      $moment
                        .utc(order.EstimatedDeliveryDate)
                        .local()
                        .format("DD/MM/YYYY")
                    }}
                  </div>
                </div>
              </div>

              <div class="col-sm-4 mb-2">
                <h6 class="mb-3">THÔNG TIN CHI TIẾT:</h6>
                <div v-if="order.Customer" class="form-row">
                  <div class="col-4">Khách hàng:</div>
                  <div class="col-8">
                    <strong
                      >{{ $const.TITLES[order.Customer.Title] }}
                      {{ order.Customer.Name }}</strong
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4">Đơn hàng:</div>
                  <div class="col-8">
                    <strong>#{{ order.OrderNumber }}</strong>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4">Ngày đặt:</div>
                  <div class="col-8">
                    {{
                      $moment
                        .utc(order.CreatedAt)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                    }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4">Thanh toán:</div>
                  <div class="col-8">
                    {{ $const.CHARGE_TYPES_TEXT[order.ChargeType] }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4">
                    Trạng thái:
                  </div>
                  <div class="col-8">
                    <CBadge :color="getBadge(order.Status)">
                      {{ $const.ORDER_STATUS_TEXT[order.Status] }}
                    </CBadge>
                    <span
                      v-if="
                        order.Status == $const.ORDER_STATUS.Completed &&
                          order.ActualDeliveryDate
                      "
                    >
                      ({{
                        $moment
                          .utc(order.ActualDeliveryDate)
                          .local()
                          .format("DD/MM/YYYY HH:mm")
                      }})
                    </span>
                    <span
                      v-else-if="
                        order.Status == $const.ORDER_STATUS.New && order.Deposit
                      "
                    >
                      (Cọc {{ $func.addCommas(order.Deposit) }})
                    </span>
                  </div>
                </div>
                <template v-if="order.Seller">
                  <div class="form-row">
                    <div class="col-4">Nhân viên:</div>
                    <div class="col-8">{{ order.Seller.Name }}</div>
                  </div>
                  <div v-if="order.Visit.Agency" class="form-row">
                    <div class="col-4">Cửa hàng:</div>
                    <div class="col-8">{{ order.Visit.Agency.Name }}</div>
                  </div>
                </template>
              </div>
            </div>

            <div
              class="row"
              v-if="order.OrderShareds && order.OrderShareds.length"
            >
              <div class="col-sm-12 mb-2 font-italic">
                *
                {{
                  $const.SHARED_TYPES_TEXT[order.OrderShareds[0].SharedType]
                }}. Cửa hàng:
                <span class="font-weight-bold"
                  >{{ order.OrderShareds[0].SharedAgency.Name }}.</span
                >
                Nhân viên:
                <span class="font-weight-bold">{{
                  order.OrderShareds[0].SharedUser.Name
                }}</span>
              </div>
            </div>

            <div class="table-responsive-sm">
              <table
                class="table table-sm table-borderless"
                ref="lstOrderDetails"
              >
                <thead>
                  <tr class="text-white bg-info">
                    <th class="text-center" style="min-width: 40px;">#</th>
                    <th style="min-width: 200px;">Sản phẩm</th>
                    <th
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      Đơn giá
                    </th>
                    <th
                      class="text-right"
                      style="min-width: 100px;max-width:100px;"
                    >
                      Số lượng
                    </th>
                    <th
                      class="text-right"
                      style="min-width: 100px;max-width:100px;"
                    >
                      Giảm giá
                    </th>
                    <th
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      Thành tiền
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(orderDetail, i) in order.OrderDetails"
                    :key="`orderDetail-${orderDetail.Id}`"
                  >
                    <td class="text-center" style="min-width: 40px;">
                      {{ i + 1 }}
                    </td>
                    <td class="text-left" style="min-width: 200px;">
                      {{ orderDetail.ProductName }}
                      <CIcon name="cil-gift" v-if="!orderDetail.Price" />
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      {{ $func.addCommas(orderDetail.MPrice) }}
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 100px;max-width:100px;"
                    >
                      {{ orderDetail.Qty }}
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 100px;max-width:100px;"
                    >
                      -
                      {{
                        orderDetail.Price
                          ? (
                              ((orderDetail.MPrice - orderDetail.Price) /
                                orderDetail.MPrice) *
                              100
                            ).toFixed(0)
                          : 100
                      }}%
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      <strong>
                        {{
                          $func.addCommas(orderDetail.Price * orderDetail.Qty)
                        }}
                      </strong>
                    </td>
                  </tr>
                  <tr class="bg-light text-dark">
                    <td colspan="5" class="text-right">
                      <strong>Tổng tạm</strong>
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      <strong>{{ $func.addCommas(subTotal) }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="orderPromo in order.OrderPromos"
                    :key="`orderPromo-${orderPromo.Id}`"
                  >
                    <td colspan="5" class="text-right">
                      <CIcon
                        name="cil-mobile-landscape"
                        size="lg"
                        v-if="orderPromo.Value && orderPromo.Value > 0"
                      />
                      <span v-if="orderPromo.Value && orderPromo.Value > 0">
                        {{ orderPromo.PromoName }}
                      </span>
                      <span v-if="orderPromo.Value && orderPromo.Value < 0">
                        (Thu phí)
                      </span>
                    </td>
                    <td class="text-right">
                      {{ orderPromo.Value && orderPromo.Value > 0 ? "-" : "" }}
                      <span v-if="orderPromo.IsPercent">
                        {{
                          $func.addCommas(subTotal * (orderPromo.Value / 100))
                        }}
                      </span>
                      <span v-else-if="orderPromo.Value > 0">
                        {{ $func.addCommas(orderPromo.Value) }}
                      </span>
                      <span v-else>
                        {{ $func.addCommas(-orderPromo.Value) }}
                      </span>
                    </td>
                  </tr>
                  <tr class="bg-light text-dark">
                    <td colspan="5" class="text-right">
                      <strong>Tổng giá trị đơn hàng</strong>
                    </td>
                    <td
                      class="text-right"
                      style="min-width: 120px;max-width:120px;"
                    >
                      <strong>{{ $func.addCommas(grandTotal) }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="row"
              v-if="
                order && order.Status == $const.ORDER_STATUS.New && allowEdit
              "
            >
              <CCol md="3">
                <CSelect
                  label="Trạng thái:"
                  placeholder="Trạng thái"
                  :value.sync="orderStatus"
                  :options="
                    Object.keys($const.ORDER_STATUS_TEXT)
                      .filter((status) => {
                        if (status != order.Status) {
                          if (
                            order.OrderInstallments &&
                            order.OrderInstallments.length
                          ) {
                            return status != $const.ORDER_STATUS.CustCancel;
                          }
                          return true;
                        }
                        return false;
                      })
                      .map((_) => {
                        return { value: _, label: $const.ORDER_STATUS_TEXT[_] };
                      })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['orderStatus'],
                  }"
                  :invalid-feedback="inValidObject['orderStatus']"
                  :description="
                    order.OrderInstallments && order.OrderInstallments.length
                      ? `Đơn hàng đã làm hồ sơ trả góp`
                      : orderStatus == $const.ORDER_STATUS.Completed &&
                        order.Deposit < grandTotal
                      ? `Lưu ý đơn hàng cần thanh toán đủ ${$func.addCommas(
                          grandTotal - order.Deposit
                        )} còn lại!`
                      : ''
                  "
                />
              </CCol>
              <template v-if="orderStatus == $const.ORDER_STATUS.Completed">
                <CCol md="3">
                  <div class="form-group">
                    <label>
                      Ngày giao
                    </label>
                    <div>
                      <datetime
                        type="datetime"
                        v-model="deliveryDate"
                        :min-datetime="
                          $moment(order.CreatedAt)
                            .local()
                            .startOf('day')
                            .utc()
                            .toISOString()
                        "
                        format="dd/MM/yyyy HH:mm"
                        :input-class="{
                          'form-control': true,
                          'is-invalid': !!inValidObject['deliveryDate'],
                        }"
                        :class="{
                          'is-invalid': !!inValidObject['deliveryDate'],
                        }"
                      ></datetime>
                      <div class="invalid-feedback">
                        {{ inValidObject["deliveryDate"] }}
                      </div>
                    </div>
                  </div>
                </CCol>

                <template
                  v-if="
                    !order.OrderInstallments || !order.OrderInstallments.length
                  "
                >
                  <CCol md="1">
                    <div role="group" class="form-group">
                      <label class="font-weight-bold text-danger">
                        Trả góp?
                      </label>
                      <div class="bg-gradient-light pl-2 pb-2 rounded">
                        <CSwitch
                          class="col-form-label"
                          color="info"
                          size="sm"
                          shape="pill"
                          label
                          :checked.sync="isInstallment"
                        />
                      </div>
                    </div>
                  </CCol>
                  <CCol md="3" v-if="isInstallment">
                    <div role="group" class="form-group">
                      <label class="text-danger font-weight-bold">
                        Tiền trả trước? (Trừ cọc)
                      </label>
                      <money
                        placeholder="Tiền trả trước"
                        :value="orderPrepay"
                        class="form-control text-right"
                        :class="{
                          'is-invalid': !!inValidObject['orderPrepay'],
                        }"
                        @input="
                          (e) => {
                            orderPrepay = e
                              ? parseFloat(e) >= 0
                                ? parseFloat(e)
                                : 0
                              : 0;
                          }
                        "
                      />
                      <div class="invalid-feedback">
                        {{ inValidObject["orderPrepay"] }}
                      </div>
                      <small class="form-text text-muted w-100">
                        {{
                          orderPrepay && grandTotal && grandTotal >= orderPrepay
                            ? `Trả trước (gồm cọc) ${(
                                ((orderPrepay + order.Deposit) / grandTotal) *
                                100
                              ).toFixed(
                                0
                              )}% tổng tiền. Chuyển trả góp: ${$func.addCommas(
                                grandTotal - orderPrepay - order.Deposit
                              )}. `
                            : ""
                        }}
                      </small>
                    </div>
                  </CCol>
                  <CCol md="2" v-if="isInstallment">
                    <CSelect
                      label="Thời gian trả góp?"
                      placeholder="Thời gian trả góp"
                      :value.sync="orderMonths"
                      :options="
                        Object.keys($const.INSTALLMENT_MONTHS).map((_) => {
                          return {
                            value: _,
                            label: $const.INSTALLMENT_MONTHS[_],
                          };
                        })
                      "
                      addLabelClasses="text-danger font-weight-bold"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['orderMonths'],
                      }"
                      :invalid-feedback="inValidObject['orderMonths']"
                      :description="
                        orderPrepay &&
                        grandTotal &&
                        orderPrepay <= grandTotal &&
                        orderMonths
                          ? `Trả góp mỗi tháng: ${$func.addCommas(
                              Math.round(
                                (grandTotal - orderPrepay - order.Deposit) /
                                  parseInt(orderMonths)
                              )
                            )}`
                          : ''
                      "
                    />
                  </CCol>
                </template>
              </template>

              <CCol
                md="3"
                v-if="
                  orderStatus == $const.ORDER_STATUS.Cancel ||
                    orderStatus == $const.ORDER_STATUS.CustCancel
                "
              >
                <CInput
                  label="Lý do hủy:"
                  placeholder="Lý do hủy"
                  v-model="orderNote"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['orderNote'],
                  }"
                  :invalid-feedback="inValidObject['orderNote']"
                />
              </CCol>
              <CCol md="2" v-if="orderStatus == $const.ORDER_STATUS.CustCancel">
                <div role="group" class="form-group">
                  <label class="font-weight-bold text-danger">
                    Hoàn cọc?
                  </label>
                  <div class="bg-gradient-light pl-2 pb-2 rounded">
                    <CSwitch
                      class="col-form-label"
                      color="info"
                      size="sm"
                      shape="pill"
                      label
                      :checked.sync="isReturnDeposit"
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer v-if="order">
      <div class="form-row w-100">
        <CCol
          class="text-left col-4"
          v-if="
            order.Status == $const.ORDER_STATUS.New ||
              order.Status == $const.ORDER_STATUS.Completed
          "
        >
          <receipt :order="order" />
        </CCol>
        <CCol class="text-right col-8 ml-auto">
          <template
            v-if="
              orderStatus &&
                order.Status == $const.ORDER_STATUS.New &&
                allowEdit
            "
          >
            <div
              v-if="
                orderStatus == $const.ORDER_STATUS.Completed &&
                  billAddress.Mobile
              "
              class="text-warning font-italic"
            >
              Chú ý: Khi đơn hàng giao thành công, hệ thống sẽ ghi nhận yêu cầu
              gửi tin nhắn zalo tới khách hàng!
            </div>
            <CButton
              class="btn btn-primary"
              @click="saveOrder"
              :disabled="saving"
            >
              <CIcon name="cil-save" custom-classes="c-icon m-0" />
              Cập nhật
            </CButton>
            <div class="d-inline-block font-italic" v-if="saving">
              <CSpinner color="info" size="sm" class="ml-2" />
              Đang xử lý...
            </div>
          </template>
          <CButton class="btn btn-secondary ml-2" @click="detailModal = false">
            <span>×</span>
            Đóng
          </CButton>
        </CCol>
      </div>
    </template>
  </CModal>
</template>
<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Receipt from "./Receipt.vue";

export default {
  props: ["editing", "orderId"],
  components: {
    Datetime,
    Receipt,
  },
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      order: null,
      saving: false,
      orderStatus: null,
      orderNote: null,
      isInstallment: false,
      orderPrepay: 0,
      orderMonths: Object.keys(this.$const.INSTALLMENT_MONTHS)[0],
      deliveryDate: null, //new Date().toISOString(),
      isReturnDeposit: false,
      loading: false,
    };
  },
  watch: {
    async orderId(val) {
      this.loading = true;
      this.order = null;
      let order = await this.loadOrderInfo(val);
      // if (order && order.Visit.IsPast) {
      //   this.deliveryDate = order.CreatedAt;
      // }
      this.order = order;
      this.orderStatus = null;
      this.orderNote = null;
      this.isInstallment = false;
      this.orderPrepay = 0;
      this.orderMonths = Object.keys(this.$const.INSTALLMENT_MONTHS)[0];
      this.isReturnDeposit = false;
      this.loading = false;

      setTimeout(() => {
        if (
          this.$refs.lstOrderDetails &&
          this.$refs.lstOrderDetails.parentElement
        ) {
          this.$refs.lstOrderDetails.parentElement.scrollLeft = this.$refs.lstOrderDetails.clientWidth;
        }
      }, 1000);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:orderId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },
  computed: {
    billAddress() {
      if (this.order && this.order.BillAddress) {
        return JSON.parse(this.order.BillAddress);
      }
      return null;
    },

    deliveryAddress() {
      if (this.order && this.order.DeliveryAddress) {
        return JSON.parse(this.order.DeliveryAddress);
      }
      return null;
    },

    subTotal() {
      return this.order.Price;
    },

    discount() {
      return this.order.Discount;
    },
    grandTotal() {
      return this.order.Total;
    },
    allowEdit() {
      let authUser = this.$user.getters.authUser;
      if (authUser) {
        let isAdmin =
          authUser.role == this.$const.ROLES.SuperAdmin ||
          (authUser.role == this.$const.ROLES.Owner &&
            authUser.agencyIds == "all");
        let userAgencyIds = authUser.agencyIds
          ? authUser.agencyIds != "all" && authUser.agencyIds.includes(",")
            ? authUser.agencyIds.split(",").map((_) => parseInt(_))
            : [parseInt(authUser.agencyIds)]
          : [];
        return (
          isAdmin ||
          (this.order.Visit.Agency &&
            userAgencyIds.includes(this.order.Visit.Agency.Id))
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    async loadOrderInfo(orderId) {
      try {
        if (orderId) {
          let filters = [];
          orderId && filters.push(`Id eq ${orderId}`);
          let resp = await this.$http.get(`odata/Order`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $expand:
                `OrderDetails($orderby=Price desc;$filter=Status eq ${this.$const.STATUS.Active}), ` +
                `OrderPromos($orderby=CreatedAt desc;$filter=Status eq ${this.$const.STATUS.Active}), ` +
                `Customer($select=Title,Name), ` +
                `Visit($select=IsPast;$expand=Agency($select=Id,Name,Address,FullName,TaxCode,Phone,AcInfo)), ` +
                `Seller($select=Name), ` +
                `OrderInstallments($top=1;$filter=Status eq ${this.$const.STATUS.Active}), ` +
                `OrderShareds($top=1;$select=SharedType;$filter=Status eq ${this.$const.STATUS.Active};$expand=SharedAgency($select=Name),SharedUser($select=Name)), ` +
                `OrderPayments($select=Amount,PaymentType,OrderId;$filter=Status eq ${this.$const.STATUS.Active};$orderBy=CreatedAt desc)`,
            },
          });
          if (resp && resp.status == 200) {
            return resp.data.value[0];
          }
        }
      } catch (error) {
        alert(error);
      }
      return null;
    },

    getBadge(status) {
      switch (status) {
        case this.$const.ORDER_STATUS.Completed:
          return "success";
        case this.$const.ORDER_STATUS.New:
          return "warning";
        case this.$const.ORDER_STATUS.Cancel:
          return "secondary";
        case this.$const.ORDER_STATUS.CustCancel:
          return "secondary";
        default:
          "primary";
      }
    },

    async saveOrder() {
      let order = this.order;
      let orderStatus = this.orderStatus;
      let orderNote = this.orderNote;
      let deliveryDate = this.deliveryDate;
      let isInstallment = this.isInstallment;
      let orderPrepay = this.orderPrepay;
      let orderMonths = this.orderMonths;
      let isReturnDeposit = this.isReturnDeposit;

      this.inValidObject = this.checkValid(
        orderStatus,
        orderNote,
        deliveryDate,
        isInstallment,
        orderPrepay,
        orderMonths,
        order.Total,
        order.Deposit
      );

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        //let delivery = deliveryDate; //this.$moment(deliveryDate, "DD/MM/YYYY HH:mm");
        let resp = await this.$http.post(`Order/ChangeStatus`, {
          OrderId: order.Id,
          OrderStatus: parseInt(orderStatus),
          OrderNote: orderNote,
          DeliveryDate: deliveryDate,
          ChargeType: isInstallment
            ? parseInt(this.$const.CHARGE_TYPES.Installment)
            : null,
          Prepay: isInstallment ? orderPrepay : null,
          Months: isInstallment ? parseInt(orderMonths) : null,
          ReturnDeposit:
            orderStatus == this.$const.ORDER_STATUS.CustCancel
              ? isReturnDeposit
              : null,
        });
        if (resp && resp.status == 200) {
          let omniMessage = "";
          let caresoftMessage = "";
          if (resp.data.status == this.$const.RESPONSE_TYPES.Success) {
            // Send Omni Message
            omniMessage = await this.sendOmniMessage(
              orderStatus,
              deliveryDate,
              order,
              this.billAddress,
              this.deliveryAddress
            );

            // Create Caresoft Ticket
            caresoftMessage = await this.caresoftCreateTicket(
              orderStatus,
              order,
              this.billAddress
            );

            // Close detail
            this.detailModal = false;
            this.$emit("reload");
          }
          if (resp.data.message) {
            let orderMessage = this.$const.MESSAGE[resp.data.message];
            let totalMessage = [orderMessage, omniMessage, caresoftMessage]
              .filter((_) => _)
              .join(" ");
            alert(totalMessage);
          }
        } else {
          alert(this.$const.MESSAGE.InvalidOrderInfo);
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(
      orderStatus,
      orderNote,
      deliveryDate,
      isInstallment,
      orderPrepay,
      orderMonths,
      grandTotal,
      deposit
    ) {
      let inValidObject = {};
      if (!orderStatus) {
        inValidObject["orderStatus"] = "Vui lòng chọn Trạng thái";
      }
      if (orderStatus == this.$const.ORDER_STATUS.Completed && !deliveryDate) {
        inValidObject["deliveryDate"] = "Vui lòng cung cấp Ngày giao";
      }
      if (
        (orderStatus == this.$const.ORDER_STATUS.Cancel ||
          orderStatus == this.$const.ORDER_STATUS.CustCancel) &&
        !orderNote
      ) {
        inValidObject["orderNote"] = "Vui lòng cung cấp Lý do hủy";
      }

      if (isInstallment) {
        if (orderPrepay) {
          if (orderPrepay < 0) {
            inValidObject["orderPrepay"] = "Tiền Trả trước phải lớn hơn 0";
          } else if (orderPrepay > grandTotal - deposit) {
            inValidObject["orderPrepay"] =
              "Tiền Trả trước (gồm cọc) phải nhỏ hơn tổng tiền";
          }
        } else {
          inValidObject["orderPrepay"] = "Nhập thông tin Trả góp";
        }
      }

      return inValidObject;
    },

    async sendOmniMessage(
      orderStatus,
      deliveryDate,
      order,
      billAddress,
      deliveryAddress
    ) {
      try {
        if (
          orderStatus == this.$const.ORDER_STATUS.Completed &&
          billAddress.Mobile
        ) {
          let resp = await this.$http.post(`order/OmniMessageSend`, {
            Mobile: billAddress.Mobile,
            CustomerName: order.Customer.Name,
            OrderCode: `#${order.OrderNumber}`,
            Address: [
              deliveryAddress.Address,
              deliveryAddress.Ward,
              deliveryAddress.District,
              deliveryAddress.Province,
            ]
              .filter((_) => _)
              .join(", "),
            PhoneNumber: deliveryAddress.Mobile
              ? deliveryAddress.Mobile
              : "none",
            Product: order.OrderDetails.filter((_) => _.Price > 0)
              .map((_) => _.ProductName)
              .join(", "),
            Date: this.$moment(deliveryDate).format("DD/MM/YYYY"),
            Money: `${this.$func.addCommas(order.Total)} VND`,
            Note: order.Note ? order.Note : "none",
          });
          if (resp && resp.status == 200) {
            if (resp.data.message) {
              return this.$const.MESSAGE[resp.data.message];
            }
          }
          return "Không thể gửi tin zalo cho khách hàng!";
        }
      } catch (error) {
        return error;
      }
    },

    async caresoftCreateTicket(orderStatus, order, billAddress) {
      try {
        if (
          orderStatus == this.$const.ORDER_STATUS.Completed &&
          billAddress.Mobile
        ) {
          let productName = order.OrderDetails.filter((_) => _.Price > 0)
            .map((_) => _.ProductName)
            .join(", ");

          let resp = await this.$http.post(`order/CaresoftCreateTicket`, {
            Mobile: billAddress.Mobile,
            CustName: order.Customer.Name,
            Subject: `${productName}`,
            Comment: `Mã đơn hàng: #${order.OrderNumber}. \nTên sản phẩm: ${productName}`,
          });
          if (resp && resp.status == 200) {
            if (resp.data.message) {
              return this.$const.MESSAGE[resp.data.message];
            }
          }
          return "Không thể tạo phiếu ghi CSKH!";
        }
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
