<template>
  <div class="d-inline-block">
    <CButton
      class="btn btn-warning"
      :disabled="loading"
      @click="generateReport"
    >
      <CIcon name="cil-print" custom-classes="c-icon m-0" />
      In hóa đơn
    </CButton>
    <div class="d-inline-block font-italic" v-if="loading">
      <CSpinner color="info" size="sm" class="ml-2" />
      Đang xử lý...
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="order.OrderNumber"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @hasStartedGeneration="loading = true"
      @hasGenerated="loading = false"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="receipt-document small">
        <div class="header">
          <div class="row align-items-center">
            <div class="col-3">
              <img src="/img/logo.png" class="img-fluid" />
            </div>
            <div class="col-9">
              <div class="text-uppercase font-weight-bold text-center">
                {{
                  agencyInfo
                    ? agencyInfo.FullName
                      ? agencyInfo.FullName
                      : agencyInfo.Name
                    : "[Tên cửa hàng đầy đủ]"
                }}
              </div>
              <div>
                <!-- <div class="form-row">
                  <div class="col-4">
                    Mã số thuế <span class="font-italic">(Tax Code)</span>:
                  </div>
                  <div class="col-8 font-weight-bold">
                    {{
                      agencyInfo && agencyInfo.TaxCode
                        ? agencyInfo.TaxCode.replaceAll(/\s*/g, " ")
                        : "[Mã số thuế]"
                    }}
                  </div>
                </div> -->
                <div class="form-row">
                  <div class="col-4">
                    Địa chỉ <span class="font-italic">(Address)</span>:
                  </div>
                  <div class="col-8 font-weight-bold">
                    {{
                      agencyInfo && agencyInfo.Address
                        ? agencyInfo.Address
                        : "[Địa chỉ cửa hàng]"
                    }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4">
                    Điện thoại <span class="font-italic">(Tel)</span>:
                  </div>
                  <div class="col-8 font-weight-bold">
                    {{
                      agencyInfo && agencyInfo.Phone
                        ? agencyInfo.Phone
                        : "[Số điện thoại cửa hàng]"
                    }}
                  </div>
                  <!-- <div class="col-1">
                    Fax:
                  </div>
                  <div class="col-3 font-weight-bold">
                    0583 21 21 21
                  </div> -->
                </div>
                <!-- <div class="form-row">
                  <div class="col-4">
                    Email:
                  </div>
                  <div class="col-8 font-weight-bold">
                    ghemassageklc@gmail.com
                  </div>
                </div> -->
                <!-- <div class="form-row">
                  <div class="col-4">
                    Tài Khoản <span class="font-italic">(A/C No.)</span>:
                  </div>
                  <div class="col-8 font-weight-bold">
                    {{
                      agencyInfo && agencyInfo.AcInfo
                        ? agencyInfo.AcInfo
                        : "[Thông tin tài khoản]"
                    }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="body mt-1">
          <div class="bill-title text-center">
            <!-- <div class="qr-container">
              <qrcode-vue :value="qrLink" size="100" level="H"></qrcode-vue>
            </div> -->

            <div class="text-danger font-weight-bold" style="font-size:1rem;">
              HÓA ĐƠN BÁN HÀNG
            </div>
            <div class="font-italic">(ORDER RECEIPT)</div>
            <div>
              <div class="d-inline-block mr-2">
                Mã <span class="font-italic">(Code)</span>:
                <span class="font-weight-bold">#{{ order.OrderNumber }}</span
                >.
              </div>
              <div class="d-inline-block">
                Ngày <span class="font-italic">(Date):</span>
                <span class="font-weight-bold">{{
                  $moment
                    .utc(new Date())
                    .local()
                    .format("DD/MM/YYYY")
                }}</span
                >.
              </div>
            </div>
          </div>
          <hr
            class="my-2"
            style="border-top: 1px solid;border-color: rgba(0, 0, 10, 0.05);"
          />
          <div class="cust-info">
            <div class="form-row">
              <div class="col-4">
                Họ tên khách hàng
                <span class="font-italic">(Buyer)</span>:
              </div>
              <div class="col-8 font-weight-bold">
                {{ order.Customer.Name }}
              </div>
            </div>
            <div class="form-row" v-if="billAddress.Mobile">
              <div class="col-4">
                Điện thoại <span class="font-italic">(Mobile)</span>:
              </div>
              <div class="col-8 font-weight-bold">
                {{ billAddress.Mobile }}
              </div>
            </div>
            <div class="form-row">
              <div class="col-4">
                Địa chỉ <span class="font-italic">(Address)</span>:
              </div>
              <div class="col-8 font-weight-bold">
                {{
                  [
                    billAddress.Address,
                    billAddress.Ward,
                    billAddress.District,
                    billAddress.Province,
                  ]
                    .filter((_) => _)
                    .join(", ")
                }}
              </div>
            </div>
          </div>
          <div class="order-detail py-2">
            <table class="table table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    STT<br />
                    <span class="font-italic font-weight-normal">
                      (No.)
                    </span>
                  </th>
                  <th class="text-center">
                    Tên hàng hóa dịch vụ<br />
                    <span class="font-italic font-weight-normal">
                      (Description)
                    </span>
                  </th>
                  <th class="text-center">
                    Đơn giá<br />
                    <span class="font-italic font-weight-normal">
                      (Unit price)
                    </span>
                  </th>
                  <th class="text-center">
                    Số lượng<br />
                    <span class="font-italic font-weight-normal">
                      (Quantity)
                    </span>
                  </th>
                  <th class="text-center">
                    Giảm giá<br />
                    <span class="font-italic font-weight-normal">
                      (Discount)
                    </span>
                  </th>
                  <th class="text-center">
                    Thành tiền<br />
                    <span class="font-italic font-weight-normal">
                      (Amount)
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(orderDetail, i) in order.OrderDetails"
                  :key="`orderDetail-${i}`"
                >
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    {{ i + 1 }}
                  </td>
                  <td>{{ orderDetail.ProductName }}</td>
                  <td class="text-right">
                    {{ $func.addCommas(orderDetail.MPrice) }}
                  </td>
                  <td class="text-right">{{ orderDetail.Qty }}</td>
                  <td class="text-right">
                    -
                    {{
                      orderDetail.Price
                        ? (
                            ((orderDetail.MPrice - orderDetail.Price) /
                              orderDetail.MPrice) *
                            100
                          ).toFixed(0)
                        : 100
                    }}%
                  </td>
                  <td class="text-right">
                    {{ $func.addCommas(orderDetail.Price * orderDetail.Qty) }}
                  </td>
                </tr>
                <tr
                  v-for="(orderPromo, i) in order.OrderPromos"
                  :key="`orderPromo-${i}`"
                >
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    {{ order.OrderDetails.length + i + 1 }}
                  </td>
                  <td colspan="4">
                    <span v-if="orderPromo.Value && orderPromo.Value > 0">
                      {{ orderPromo.PromoName }}
                    </span>
                    <span v-if="orderPromo.Value && orderPromo.Value < 0">
                      (Thu phí)
                    </span>
                  </td>
                  <td class="text-right">
                    {{ orderPromo.Value && orderPromo.Value > 0 ? "-" : "" }}
                    <span v-if="orderPromo.IsPercent">
                      {{ $func.addCommas(subTotal * (orderPromo.Value / 100)) }}
                    </span>
                    <span v-else-if="orderPromo.Value > 0">
                      {{ $func.addCommas(orderPromo.Value) }}
                    </span>
                    <span v-else>
                      {{ $func.addCommas(-orderPromo.Value) }}
                    </span>
                  </td>
                </tr>
                <tr v-for="item in remainArr" :key="`item-${item}`">
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    <span style="color:transparent;">{{
                      order.OrderDetails.length +
                        order.OrderPromos.length +
                        item +
                        1
                    }}</span>
                  </td>
                  <td colspan="4"></td>
                  <td class="text-right"></td>
                </tr>
                <tr>
                  <td colspan="5">
                    Tổng tiền <span class="font-italic">(Total amount)</span>:
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ $func.addCommas(grandTotal) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="100%">
                    Tổng tiền bằng chữ
                    <span class="font-italic">(Amount in words)</span>:
                    <span class="font-weight-bold">
                      {{ $func.capitalizeFirstLetter(VNnum2words(grandTotal)) }}
                      đồng.
                    </span>
                  </td>
                </tr>
                <tr v-if="depositTotal && depositTotal > 0">
                  <td colspan="5">
                    Tiền cọc <span class="font-italic">(Deposit)</span>:
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ $func.addCommas(depositTotal) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    Thanh toán <span class="font-italic">(Payment)</span>:
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ $func.addCommas(paymentTotal) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    Còn lại
                    <span class="font-italic"> (Remaining) </span>
                    <span> {{ remainingMonthly }} </span>:
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ $func.addCommas(remainingTotal) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="footer mt-2">
          <div class="form-row">
            <div class="col-5 text-center font-weight-bold">
              Người mua hàng (Buyer)
            </div>
            <div class="col-2 text-center">
              <qrcode-vue :value="qrLink" size="90" level="H"></qrcode-vue>
            </div>
            <div class="col-5 text-center font-weight-bold">
              Người bán hàng (Seller)
            </div>
          </div>
          <div class="text-center font-italic">
            Kiểm tra thông tin bảo hành, vui lòng quét mã QR hoặc truy cập
            website:
            <span class="font-weight-bold"
              >https://baohanh.ghemassageklc.com</span
            >. Hotline CSKH:
            <span class="font-weight-bold">1800 0076</span>
          </div>
          <!-- <div class="contact-info font-italic">
            <div class="form-row">
              <div class="col-5 text-center">
                Hotline bảo hành:
                <span class="font-weight-bold">0382 53 53 53</span>
              </div>
              <div class="col-2 text-center position-relative">
                <div class="qr-container">
                  <qrcode-vue :value="qrLink" size="90" level="H"></qrcode-vue>
                </div>
              </div>
              <div class="col-5 text-center">
                Hotline CSKH:
                <span class="font-weight-bold">1800 0076</span>
              </div>
            </div>
            <div class="text-center">
              Kiểm tra thông tin bảo hành, vui lòng quét mã QR hoặc truy cập
              website: https://baohanh.ghemassageklc.com
            </div>
          </div>-->
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    VueHtml2pdf,
    QrcodeVue,
  },
  props: ["order"],
  data() {
    return {
      loading: false,
      /*
    @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    */
    };
  },
  computed: {
    billAddress() {
      if (this.order && this.order.BillAddress) {
        return JSON.parse(this.order.BillAddress);
      }
      return null;
    },

    subTotal() {
      return this.order.Price;
    },

    discount() {
      return this.order.Discount;
    },
    grandTotal() {
      return this.order.Total;
    },
    paymentsReceipt() {
      let payIncomes =
        this.order &&
        this.order.OrderPayments &&
        this.order.OrderPayments.length
          ? this.order.OrderPayments
          : [];
      if (payIncomes.length) {
        let take1 = payIncomes[0];
        if (take1.Amount < 0) {
          let take3 = payIncomes.slice(0, 3);
          return take3;
        } else if (take1.Amount == 0) {
          let take2 = payIncomes.slice(0, 2);
          return take2;
        } else {
          return [take1];
        }
      } else {
        return [];
      }
    },
    depositTotal() {
      let payment =
        this.order &&
        this.order.OrderPayments &&
        this.order.OrderPayments.length &&
        this.order.OrderPayments.length > 1 &&
        !this.order.OrderPayments.some(
          (_) => _.PaymentType == this.$const.PAYMENT_TYPES.Monthly
        )
          ? this.order.OrderPayments.filter(
              (_) => _.PaymentType == this.$const.PAYMENT_TYPES.Deposit
            ).reduce((a, b) => {
              return a + b.Amount;
            }, 0)
          : 0;
      return payment;
    },
    paymentTotal() {
      let paymentsReceipt = this.paymentsReceipt;
      return paymentsReceipt.reduce((a, b) => {
        return a + b.Amount;
      }, 0);
    },
    remainingTotal() {
      let payment =
        this.order &&
        this.order.OrderPayments &&
        this.order.OrderPayments.length
          ? this.order.OrderPayments.reduce((a, b) => {
              return a + b.Amount;
            }, 0)
          : 0;
      return this.grandTotal - payment;
    },
    remainArr() {
      let result = [];
      let count =
        9 - this.order.OrderDetails.length - this.order.OrderPromos.length;
      for (let i = 0; i < count; i++) {
        result.push(i);
      }
      return result;
    },
    agencyInfo() {
      let order = this.order;
      return order.Visit && order.Visit.Agency ? order.Visit.Agency : null;
    },
    qrLink() {
      return `https://baohanh.ghemassageklc.com?don-hang=${this.order.OrderNumber}`;
    },
    remainingMonthly() {
      let order = this.order;
      let result = "";

      if (order && order.OrderInstallments && order.OrderInstallments.length) {
        let instalment = order.OrderInstallments[0];
        if (instalment) {
          let pricePer = instalment.PricePer;
          let remaining = instalment.Remaining;
          let monthRemain = Math.round(remaining / pricePer);
          result = ` - Còn ${monthRemain} tháng trả góp`;
        }
      }

      return result;
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    //43.980.000
    VNnum2words(number) {
      let writtenNumber = require("written-number");
      return writtenNumber(number, { lang: "vi" });
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt-document {
  padding: 2rem;

  .order-detail {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 15%;
      left: calc(50% - 150px);
      background-image: url("/img/logo.png");
      background-size: 300px auto;
      // background-position: 30px 30px;
      background-repeat: no-repeat;
      opacity: 0.1;
      height: calc(100vh);
    }
    table {
      th,
      td {
        padding-top: 0;
        line-height: 0.8rem;
      }
    }
  }

  .contact-info {
    margin-top: 5rem;
  }
}

// .bill-title {
//   position: relative;

.qr-container {
  position: absolute;
  bottom: 0;
  left: 0;
}
//}
</style>
