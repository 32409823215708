<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilNotes" />
          DS Đơn hàng
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>

          <template v-if="authUser && authUser.role == $const.ROLES.SuperAdmin">
            <CSpinner v-if="exporting" color="primary" size="sm" class="mr-2" />
            <CButton
              v-else
              color="primary"
              size="sm"
              class="mr-2"
              title="export"
              @click.prevent="exportXlsx"
            >
              <CIcon name="cib-experts-exchange" />
            </CButton>
          </template>

          <CButton
            color="success"
            size="sm"
            class="float-right"
            @click.prevent="$router.push({ name: 'OrderCreate' })"
          >
            <CIcon name="cil-plus" />
            Tạo đơn hàng
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box
          v-if="showFilter"
          @done="(e) => doFilter(e)"
          :sources="sources"
        />
        <odata-table
          ref="lstOrder"
          url="odata/Order"
          tableClass="table-striped"
          :filter="filter"
          :expand="
            `OrderDetails($count=true;$top=1;$orderby=Price desc), ` +
              `Customer($select=Title,Name,LastName,Mobile), ` +
              `Seller($select=Name), ` +
              `Visit($select=Id,Source;$expand=Agency($select=Id,Name)), ` +
              `OrderShareds($count=true;$top=1;$select=SharedType;$expand=SharedAgency($select=Name),SharedUser($select=Name);$filter=Status eq ${$const.STATUS.Active})`
          "
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Agency: {
              field: 'Visit/Agency/Name',
              display: 'Cửa hàng',
              sortable: false,
              style: 'min-width: 120px',
            },
            OrderNumber: {
              field: 'OrderNumber',
              display: 'Đơn hàng',
              sortable: true,
              style: 'min-width: 100px',
            },
            Total: {
              field: 'Total',
              display: 'Thành tiền',
              sortable: true,
              style: 'min-width: 100px',
            },
            Deposit: {
              field: 'Deposit',
              display: 'Tiền cọc',
              sortable: true,
              style: 'min-width: 100px',
            },
            ChargeType: {
              field: 'ChargeType',
              display: 'Phương thức',
              sortable: true,
              style: 'min-width: 100px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
            CustomerName: {
              field: 'Customer/Name',
              display: 'Tên KH',
              sortable: true,
              style: 'min-width: 150px',
            },
            CustomerMobile: {
              field: 'Customer/Mobile',
              display: 'SĐT KH',
              sortable: true,
              style: 'min-width: 100px',
            },
            Source: {
              field: 'Visit/Source',
              display: 'Nguồn',
              sortable: true,
              style: 'min-width: 150px',
            },
            SellerName: {
              field: 'Seller/Name',
              display: 'Nhân viên',
              sortable: true,
              style: 'min-width: 120px',
            },
            Note: {
              field: 'Note',
              display: 'Ghi chú',
              sortable: true,
              style: 'min-width: 200px;max-width:200px;',
            },
            DeliveryAddress: {
              field: 'DeliveryAddress',
              display: 'Địa chỉ giao',
              sortable: true,
              style: 'min-width: 200px',
            },
            OrderDetails: {
              field: 'OrderDetails',
              display: 'Chi tiết',
              sortable: false,
              style: 'min-width: 200px',
            },
            UpdatedAt: {
              field: 'UpdatedAt',
              display: 'Cập nhật',
              sortable: true,
              style: 'min-width: 120px',
            },
            OrderShareds: {
              field: 'OrderShareds',
              display: 'Chia bill',
              sortable: false,
              style: 'min-width: 200px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-if="$refs.lstOrder.loading">
              <td colspan="100%">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>
                <div class="text-nowrap">
                  <a
                    v-if="isAdmin"
                    href="javascript:"
                    class="text-danger"
                    :title="`Xóa đơn hàng #${row.OrderNumber}`"
                    @click="deleteOrder(row)"
                  >
                    <CIcon name="cil-trash" />
                  </a>
                  <span v-if="isAdmin">|</span>
                  {{ i + 1 + ($refs.lstOrder.page - 1) * pageSize }}.
                </div>
              </td>
              <td>
                <span v-if="row.Visit">
                  {{ row.Visit.Agency ? row.Visit.Agency.Name : "" }}
                </span>
              </td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Chi tiết đơn hàng: #${row.OrderNumber}`"
                  @click="
                    (e) => {
                      orderId = row.Id;
                      editing = true;
                    }
                  "
                >
                  #{{ row.OrderNumber }}
                </a>
              </td>
              <td>{{ $func.addCommas(row.Total) }}</td>
              <td>{{ $func.addCommas(row.Deposit) }}</td>
              <td>{{ $const.CHARGE_TYPES_TEXT[row.ChargeType] }}</td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.ORDER_STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                }}
              </td>
              <td>
                {{ $const.TITLES[row.Customer.Title] }}
                <span v-if="row.Customer.LastName">
                  {{ row.Customer.LastName }}
                </span>
                {{ row.Customer.Name }}
              </td>
              <td>
                {{ row.Customer.Mobile }}
              </td>
              <td>
                {{ sources[row.Visit.Source] }}
              </td>
              <td>
                {{ row.Seller.Name }}
              </td>
              <td style="min-width:200px;max-width:200px;">
                {{ row.Note }}
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span :title="renderAddress(row.DeliveryAddress)">
                  {{ renderAddress(row.DeliveryAddress) }}
                </span>
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span
                  :title="
                    renderDetails(
                      row.OrderDetails,
                      row['OrderDetails@odata.count']
                    )
                  "
                >
                  {{
                    renderDetails(
                      row.OrderDetails,
                      row["OrderDetails@odata.count"]
                    )
                  }}
                </span>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.UpdatedAt)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                }}
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span
                  v-if="row.OrderShareds && row.OrderShareds.length"
                  :title="renderOrderShared(row.OrderShareds[0])"
                >
                  {{ renderOrderShared(row.OrderShareds[0]) }}
                </span>
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :orderId.sync="orderId"
      @reload="$refs.lstOrder.loadData()"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import filterBox from "./Filter.vue";

export default {
  name: "OrderList",
  components: {
    odataTable,
    detail,
    filterBox,
  },
  data() {
    return {
      pageSize: 10,
      orderId: 0,
      editing: false,
      showFilter: false,
      filters: null,
      exporting: false,
      sources: {},
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },

    routeOrderId() {
      let orderId = this.$route.params.orderId;
      return !isNaN(orderId) ? orderId : 0;
    },

    authUser() {
      return this.$user.getters.authUser;
    },

    isAdmin() {
      let authUser = this.authUser;
      return this.authUser
        ? authUser && authUser.role == this.$const.ROLES.SuperAdmin
        : false;
    },
  },
  mounted() {
    if (this.routeOrderId) {
      this.editing = true;
      this.orderId = this.routeOrderId;
    }

    this.getMiscList();
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.ORDER_STATUS.Completed:
          return "success";
        case this.$const.ORDER_STATUS.New:
          return "warning";
        case this.$const.ORDER_STATUS.Cancel:
          return "secondary";
        case this.$const.ORDER_STATUS.CustCancel:
          return "secondary";
        default:
          "primary";
      }
    },
    renderAddress(address) {
      if (address) {
        let addrObj = JSON.parse(address);
        return (
          addrObj.Title +
          " " +
          Object.keys(addrObj)
            .filter((_) => _ != "Title")
            .map((_) => addrObj[_])
            .filter((_) => _)
            .join(", ")
        );
      }
      return "";
    },

    renderDetails(orderDetails, count) {
      if (orderDetails && orderDetails.length) {
        let list = [];
        list.push(orderDetails[0].ProductName);
        count > 1 && list.push(`${count - 1} sản phẩm khác`);
        return list.join(" và ");
      }
      return null;
    },

    renderOrderShared(orderShared) {
      let result = [];

      if (orderShared.SharedType) {
        result.push(this.$const.SHARED_TYPES_TEXT[orderShared.SharedType]);
      }
      if (orderShared.SharedAgency) {
        result.push(orderShared.SharedAgency.Name);
      }
      if (orderShared.SharedUser) {
        result.push(orderShared.SharedUser.Name);
      }
      return result.join(". ");
    },

    async getMiscList() {
      let sources = {};
      let typeFilter = "Type in ('Source')";
      let statusFilter = `Status eq ${this.$const.STATUS.Active}`;
      let resp = await this.$http.get(`odata/Misc`, {
        params: {
          $filter: `${typeFilter} and ${statusFilter}`,
        },
      });
      if (resp && resp.status == 200) {
        resp.data.value.forEach((_) => (sources[_.Value] = _.Text));
      }
      this.sources = sources;
    },

    async exportXlsx() {
      let oCpnent = this.$refs.lstOrder;
      if (!oCpnent.total) {
        alert("Không có dữ liệu!");
        return;
      }

      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = [
        "Mã đơn hàng",
        "Ngày đặt hàng",
        "Ngày cập nhật",
        "Phương thức thanh toán",
        "Tổng tiền",
        "Tiền giảm",
        "Thành tiền",
        "Tiền cọc",
        "Doanh số thực thu",
        "Chia bill",
        "CH chia bill",
        "Trạng thái",
        "Khách hàng",
        "Nguồn",
        "Quận / Huyện",
        "Tỉnh / Thành Phố",
        "SĐT KH",
        "Nhân viên",
        "Cửa hàng",
        "Ghi chú",
        "Địa chỉ giao",
        "SL Quà tặng",
        "Quà tặng",
        "Chi tiết",
      ];

      /* XLS Rows Data */
      var xlsRows = [];

      let pageSize = 100;
      var pages =
        oCpnent.total > pageSize ? Math.ceil(oCpnent.total / pageSize) : 1;

      this.exporting = true;
      for (let page = 1; page <= pages; page++) {
        let params = {
          $select:
            "OrderNumber, CreatedAt, UpdatedAt, ChargeType, Price, Discount, Total, Deposit, Status, Note, BillAddress, DeliveryAddress",
          $expand:
            `Customer($select=Title,Name,LastName,Mobile), Seller($select=Name), Visit($select=Id,Source;$expand=Agency($select=Name)), ` +
            `OrderPayments($select=Amount;$filter=Status eq ${this.$const.STATUS.Active}), ` +
            `OrderShareds($top=1;$select=SharedType;$expand=SharedAgency($select=Name)), ` +
            `OrderDetails($filter=Price ge 0 and Status eq ${this.$const.STATUS.Active})`,
          //$filter: oCpnent.filter,
          $orderby: oCpnent.orderBy,
          $top: pageSize,
          $skip: page ? (page - 1) * pageSize : 0,
        };
        if (oCpnent.filter) {
          params.$filter = oCpnent.filter;
        }
        let resp = await this.$http.get(oCpnent.url, {
          params,
        });
        if (
          resp &&
          resp.status == 200 &&
          resp.data.value &&
          resp.data.value.length
        ) {
          xlsRows = [
            ...xlsRows,
            ...resp.data.value.map((_) => {
              return {
                OrderNumber: _.OrderNumber,
                CreatedAt: this.$moment
                  .utc(_.CreatedAt)
                  .local()
                  .format("DD/MM/YYYY HH:mm"),
                UpdatedAt: this.$moment
                  .utc(_.UpdatedAt)
                  .local()
                  .format("DD/MM/YYYY HH:mm"),
                ChargeType: this.$const.CHARGE_TYPES_TEXT[_.ChargeType],
                Price: _.Price,
                Discount: _.Discount,
                Total: _.Total,
                Deposit: _.Deposit,
                TotalAmount:
                  _.OrderPayments && _.OrderPayments.length
                    ? _.OrderPayments.reduce((acc, cur) => acc + cur.Amount, 0)
                    : 0,
                SharedType:
                  _.OrderShareds && _.OrderShareds.length
                    ? this.$const.SHARED_TYPES_TEXT[
                        _.OrderShareds[0].SharedType
                      ]
                    : "",
                SharedAgency:
                  _.OrderShareds && _.OrderShareds.length
                    ? _.OrderShareds[0].SharedAgency.Name
                    : "",
                Status: this.$const.ORDER_STATUS_TEXT[_.Status],
                Customer:
                  this.$const.TITLES[_.Customer.Title] +
                  " " +
                  (_.Customer.LastName
                    ? `${_.Customer.LastName} ${_.Customer.Name}`
                    : _.Customer.Name),
                Source: this.sources[_.Visit.Source],
                District: _.BillAddress
                  ? JSON.parse(_.BillAddress).District
                  : "",
                Province: _.BillAddress
                  ? JSON.parse(_.BillAddress).Province
                  : "",
                Mobile: _.Customer.Mobile,
                Seller: _.Seller.Name,
                Agency: _.Visit && _.Visit.Agency ? _.Visit.Agency.Name : "",
                Note: _.Note,
                Delivery: this.renderAddress(_.DeliveryAddress),
                GiftCount: _.OrderDetails
                  ? _.OrderDetails.filter((g) => g.Price == 0).length
                  : 0,
                GiftItems: _.OrderDetails
                  ? _.OrderDetails.filter((g) => g.Price == 0)
                      .map((d) => d.ProductName)
                      .join(", ")
                  : "",
                BuyItems: _.OrderDetails
                  ? _.OrderDetails.filter((g) => g.Price > 0)
                      .map((d) => d.ProductName)
                      .join(", ")
                  : "",
              };
            }),
          ];
        }
      }
      this.exporting = false;

      if (!xlsRows.length) {
        alert("Không có dữ liệu!");
        return;
      }

      createXLSLFormatObj.push(xlsHeader);
      xlsRows.forEach((value) => {
        var innerRowData = [];
        Object.keys(value).forEach((p) => {
          innerRowData.push(value[p]);
        });
        createXLSLFormatObj.push(innerRowData);
      });

      /* File Name */
      var filename = `orders_${this.$moment().format("YYYYMMDD")}.xlsx`;

      /* Sheet Name */
      var ws_name = "Orders";

      if (typeof console !== "undefined") console.log(new Date());
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

      /* Add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* Write workbook and Download */
      if (typeof console !== "undefined") console.log(new Date());
      XLSX.writeFile(wb, filename);
      if (typeof console !== "undefined") console.log(new Date());
    },

    doFilter(e) {
      if (JSON.stringify(this.filters) != JSON.stringify(e)) {
        this.filters = e;
      } else {
        this.$refs.lstOrder.loadData();
      }
    },

    async deleteOrder(order) {
      if (
        confirm(`Bạn có chắc chắn muốn xóa đơn hàng #${order.OrderNumber}?`)
      ) {
        await this.$http.delete(`odata/Order/${order.Id}`);
        this.$refs.lstOrder.loadData();
      }
    },
  },
};
</script>
