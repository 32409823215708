var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilNotes"}}),_vm._v(" DS Đơn hàng ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1),(_vm.authUser && _vm.authUser.role == _vm.$const.ROLES.SuperAdmin)?[(_vm.exporting)?_c('CSpinner',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm"}}):_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm","title":"export"},on:{"click":function($event){$event.preventDefault();return _vm.exportXlsx($event)}}},[_c('CIcon',{attrs:{"name":"cib-experts-exchange"}})],1)]:_vm._e(),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'OrderCreate' })}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Tạo đơn hàng ")],1)],2)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{attrs:{"sources":_vm.sources},on:{"done":function (e) { return _vm.doFilter(e); }}}):_vm._e(),_c('odata-table',{ref:"lstOrder",attrs:{"url":"odata/Order","tableClass":"table-striped","filter":_vm.filter,"expand":"OrderDetails($count=true;$top=1;$orderby=Price desc), " +
            "Customer($select=Title,Name,LastName,Mobile), " +
            "Seller($select=Name), " +
            "Visit($select=Id,Source;$expand=Agency($select=Id,Name)), " +
            "OrderShareds($count=true;$top=1;$select=SharedType;$expand=SharedAgency($select=Name),SharedUser($select=Name);$filter=Status eq " + (_vm.$const.STATUS.Active) + ")","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Agency: {
            field: 'Visit/Agency/Name',
            display: 'Cửa hàng',
            sortable: false,
            style: 'min-width: 120px',
          },
          OrderNumber: {
            field: 'OrderNumber',
            display: 'Đơn hàng',
            sortable: true,
            style: 'min-width: 100px',
          },
          Total: {
            field: 'Total',
            display: 'Thành tiền',
            sortable: true,
            style: 'min-width: 100px',
          },
          Deposit: {
            field: 'Deposit',
            display: 'Tiền cọc',
            sortable: true,
            style: 'min-width: 100px',
          },
          ChargeType: {
            field: 'ChargeType',
            display: 'Phương thức',
            sortable: true,
            style: 'min-width: 100px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
          CustomerName: {
            field: 'Customer/Name',
            display: 'Tên KH',
            sortable: true,
            style: 'min-width: 150px',
          },
          CustomerMobile: {
            field: 'Customer/Mobile',
            display: 'SĐT KH',
            sortable: true,
            style: 'min-width: 100px',
          },
          Source: {
            field: 'Visit/Source',
            display: 'Nguồn',
            sortable: true,
            style: 'min-width: 150px',
          },
          SellerName: {
            field: 'Seller/Name',
            display: 'Nhân viên',
            sortable: true,
            style: 'min-width: 120px',
          },
          Note: {
            field: 'Note',
            display: 'Ghi chú',
            sortable: true,
            style: 'min-width: 200px;max-width:200px;',
          },
          DeliveryAddress: {
            field: 'DeliveryAddress',
            display: 'Địa chỉ giao',
            sortable: true,
            style: 'min-width: 200px',
          },
          OrderDetails: {
            field: 'OrderDetails',
            display: 'Chi tiết',
            sortable: false,
            style: 'min-width: 200px',
          },
          UpdatedAt: {
            field: 'UpdatedAt',
            display: 'Cập nhật',
            sortable: true,
            style: 'min-width: 120px',
          },
          OrderShareds: {
            field: 'OrderShareds',
            display: 'Chia bill',
            sortable: false,
            style: 'min-width: 200px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return [(_vm.$refs.lstOrder.loading)?_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]):_vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_c('div',{staticClass:"text-nowrap"},[(_vm.isAdmin)?_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:","title":("Xóa đơn hàng #" + (row.OrderNumber))},on:{"click":function($event){return _vm.deleteOrder(row)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1):_vm._e(),(_vm.isAdmin)?_c('span',[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(i + 1 + (_vm.$refs.lstOrder.page - 1) * _vm.pageSize)+". ")])]),_c('td',[(row.Visit)?_c('span',[_vm._v(" "+_vm._s(row.Visit.Agency ? row.Visit.Agency.Name : "")+" ")]):_vm._e()]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Chi tiết đơn hàng: #" + (row.OrderNumber))},on:{"click":function (e) {
                    _vm.orderId = row.Id;
                    _vm.editing = true;
                  }}},[_vm._v(" #"+_vm._s(row.OrderNumber)+" ")])]),_c('td',[_vm._v(_vm._s(_vm.$func.addCommas(row.Total)))]),_c('td',[_vm._v(_vm._s(_vm.$func.addCommas(row.Deposit)))]),_c('td',[_vm._v(_vm._s(_vm.$const.CHARGE_TYPES_TEXT[row.ChargeType]))]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.ORDER_STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$const.TITLES[row.Customer.Title])+" "),(row.Customer.LastName)?_c('span',[_vm._v(" "+_vm._s(row.Customer.LastName)+" ")]):_vm._e(),_vm._v(" "+_vm._s(row.Customer.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.Customer.Mobile)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sources[row.Visit.Source])+" ")]),_c('td',[_vm._v(" "+_vm._s(row.Seller.Name)+" ")]),_c('td',{staticStyle:{"min-width":"200px","max-width":"200px"}},[_vm._v(" "+_vm._s(row.Note)+" ")]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":_vm.renderAddress(row.DeliveryAddress)}},[_vm._v(" "+_vm._s(_vm.renderAddress(row.DeliveryAddress))+" ")])]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":_vm.renderDetails(
                    row.OrderDetails,
                    row['OrderDetails@odata.count']
                  )}},[_vm._v(" "+_vm._s(_vm.renderDetails( row.OrderDetails, row["OrderDetails@odata.count"] ))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.UpdatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[(row.OrderShareds && row.OrderShareds.length)?_c('span',{attrs:{"title":_vm.renderOrderShared(row.OrderShareds[0])}},[_vm._v(" "+_vm._s(_vm.renderOrderShared(row.OrderShareds[0]))+" ")]):_vm._e()])])})]}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"orderId":_vm.orderId},on:{"update:editing":function($event){_vm.editing=$event},"update:orderId":function($event){_vm.orderId=$event},"update:order-id":function($event){_vm.orderId=$event},"reload":function($event){return _vm.$refs.lstOrder.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }